import { useState } from "react";
import "./TimelineElement.css";

type TimelineElementProps = {
	experience: Experience;
};

export default function TimelineElement(_props: TimelineElementProps) {
	let [iconRoundAnimation, setIconRoundAnimation] = useState("w-6 h-6");
	let [titleAnimation, setTitleAnimation] = useState("");
	let pointElStyle: string = "bg-color-second";
	let colorElStyle: string = "color-second";
	switch (_props.experience.type) {
		case "study":
			pointElStyle = "bg-color-outline";
			colorElStyle = "color-fourth";
			break;
		case "work":
			pointElStyle = "bg-color-third";
			colorElStyle = "color-third";
			break;
	}

	return (
		<li
			className={`md:relative md:mb-0 flex md:h-44 md:inline gap-4 min-h-[52px] ${colorElStyle}`}
			onMouseEnter={() => {
				setIconRoundAnimation("icon-big");
				setTitleAnimation("text-big");
			}}
			onMouseLeave={() => {
				setIconRoundAnimation("icon-regular");
				setTitleAnimation("text-regular");
			}}
		>
			<div className="flex items-center flex-col md:flex-row">
				<div
					className={`absolute flex z-10 justify-center items-center ${iconRoundAnimation} rounded-full shrink-0 ${pointElStyle}`}
				></div>
				<div className="flex md:w-full md:h-0.5 h-full w-0.5 bg-color-fourth"></div>
			</div>
			<div className="md:mt-6 mt-2 md:pr-8 flex flex-col justify-between gap-2 mb-4 md:mb-0 ml-3 md:ml-0">
				<time className="mb-1 text-xs font-normal leading-none italic small-font font-200">
					{_props.experience.time} {" - "}
					<a
						href={_props.experience.locationURL}
						className="small-font font-200"
					>
						{_props.experience.location}
					</a>
				</time>
				{_props.experience.title ? (
					_props.experience.titleURL ? (
						<h3>
							<a
								className={`font-bold small-font font-400 ${titleAnimation}`}
								href={_props.experience.titleURL}
							>
								{_props.experience.title}
							</a>
						</h3>
					) : (
						<h3 className={`font-bold small-font font-400 ${titleAnimation}`}>
							{_props.experience.title}
						</h3>
					)
				) : (
					""
				)}
				{_props.experience.description ? (
					<h4 className="small-font font-200">
						{_props.experience.description}
					</h4>
				) : (
					""
				)}
			</div>
		</li>
	);
}

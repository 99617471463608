import AnimatedText from "./components/AnimatedText";
import BoidsSimulationCanvas from "./components/BoidsSimulationCanvas";
import GetPortFolioTranslation from "./Traslations";
import { Navigation } from "swiper";
import PerlinNoiseParticleCanvas from "./components/PerlinNoiseParticleCanvas";
import PopupCard from "./components/PopupCard";
import SierpinskyTriangleCanvas from "./components/SierpinskyTriangleCanvas";
import { Swiper, SwiperSlide } from "swiper/react";
import Timeline from "./components/Timeline";
import { useState, useEffect } from "react";
import "./App.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

let forward = true;

function App() {
	let [click, setClick] = useState(1);
	let [lang, setLang] = useState(JSON.parse(localStorage.getItem("lang") + ""));
	let [notificationStyle, setNotificationStyle] = useState("hidden");
	let [portfolioTranslation, setPortFolioTranslation] = useState(
		GetPortFolioTranslation(lang)
	);
	let [slideNo, setSlideNo] = useState(0);
	const [visibility, setVisibility] = useState(false);

	useEffect(() => {
		const lang = JSON.parse(localStorage.getItem("lang") + "");
		if (lang) {
			setLang(lang);
		}
	}, []);

	useEffect(() => {
		localStorage.setItem("lang", JSON.stringify(lang));
	});

	document.addEventListener("keyup", (ev) => {
		if (ev.key === "Escape") {
			setVisibility(false);
		}
	});

	function OnCloseHandler() {
		setVisibility(false);
	}

	return (
		<div>
			{/* home section */}
			<section className="px-4 md:px-16 mt-10 flex gap-10 flex-col-reverse md:flex-row justify-between items-center">
				<div className="md:w-1/2 flex flex-col justify-center">
					<AnimatedText
						className="text-5xl md:text-8xl"
						tag="h1"
						text={portfolioTranslation?.presentationSentence}
					/>
					<p className="mt-4">
						{portfolioTranslation?.presentationDescription.map((value, i) => {
							return (
								<span key={i} className="small-font font-400">
									{value}
									{i + 1 ===
									portfolioTranslation.presentationDescription.length ? (
										""
									) : (
										<br />
									)}
								</span>
							);
						})}
					</p>
					<div className="flex px-5 justify-center md:justify-start gap-14 md:gap-16 mt-5">
						{portfolioTranslation.socials.map((value, i) => {
							return (
								<a key={i} href={value.socialURL}>
									<img
										className="w-8 cursor-pointer"
										src={`${value.social}.svg`}
										alt={value.social}
									/>
								</a>
							);
						})}
					</div>
				</div>
				<div className="w-[300px] md:w-1/2 max-w-[400px] flex justify-center">
					<img
						className="overflow-hidden rounded-full w-full h-full"
						src={`portrait${click}.png`}
						alt="avatar"
						onClick={() => {
							if (click === 4) {
								forward = false;
							} else if (click === 1) {
								forward = true;
							}
							setClick(forward ? click + 1 : click - 1);
						}}
					/>
				</div>
			</section>
			{/* timeline section */}
			<section className="px-4 md:px-16 mt-10 items-center justify-center">
				<h2 className="mb-12 text-2xl">
					{portfolioTranslation.presentationTitle[0]}{" "}
					<span className="color-second">
						{portfolioTranslation.presentationTitle[1]}
					</span>
					, {portfolioTranslation.presentationTitle[2]}
					<span className="color-outline">
						{portfolioTranslation.presentationTitle[3]}
					</span>{" "}
					{portfolioTranslation.presentationTitle[4]}
					<span className="color-third">
						{portfolioTranslation.presentationTitle[5]}
					</span>
				</h2>
				<Timeline experiences={portfolioTranslation?.experiences} />
			</section>
			{/* experiments section */}
			<section className="px-4 md:px-16 mt-10 items-center justify-center">
				<h2 className="mb-5 text-2xl">
					{portfolioTranslation.experimentsTitle}
				</h2>
				<Swiper
					modules={[Navigation]}
					slidesPerView={1}
					navigation
					className="h-[90vh] w-full"
					onSlideChange={(swiper) => {
						setSlideNo(swiper.activeIndex);
					}}
				>
					<SwiperSlide className="md:px-10 h-full w-full">
						<BoidsSimulationCanvas active={slideNo === 0} />
						<div className="small-font font-400 z-10 md:mt-[-7%] mt-[-15%] text-center">
							{portfolioTranslation.experiments[slideNo].title}
						</div>
						<button
							className="absolute top-10 right-6 md:right-20 small-font w-8 h-8 rounded-full border bg-color-first hover:border-gray-400"
							onClick={() => setVisibility(true)}
						>
							i
						</button>
					</SwiperSlide>
					<SwiperSlide className="md:px-10 h-full w-full">
						<SierpinskyTriangleCanvas active={slideNo === 1} />
						<div className="small-font font-400 z-10 md:mt-[-7%] mt-[-15%] text-center">
							{portfolioTranslation.experiments[slideNo].title}
						</div>
						<button
							className="absolute top-10 right-6 md:right-20 small-font w-8 h-8 rounded-full border bg-color-first hover:border-gray-400"
							onClick={() => setVisibility(true)}
						>
							i
						</button>
					</SwiperSlide>
					<SwiperSlide className="md:px-10 h-full w-full">
						<PerlinNoiseParticleCanvas active={slideNo === 2} />
						<div className="flex justify-center md:mt-[-7%] mt-[-15%]">
							<div className="z-10 bg-color-first h-8 rounded px-4">
								<div className="small-font font-400 h-8">
									{portfolioTranslation.experiments[slideNo].title}
								</div>
							</div>
						</div>
						<button
							className="absolute top-10 right-6 md:right-20 small-font w-8 h-8 rounded-full border bg-color-first hover:border-gray-400"
							onClick={() => setVisibility(true)}
						>
							i
						</button>
					</SwiperSlide>
				</Swiper>
			</section>
			{/* popup */}
			<PopupCard
				onCloseHandler={OnCloseHandler}
				sections={portfolioTranslation.experiments[slideNo].experimentSections}
				title={portfolioTranslation.experiments[slideNo].title}
				visible={visibility}
			/>
			{/* notification */}
			<div
				className={`${notificationStyle} notification leading-normal rounded-lg border bg-white w-40 z-40`}
			>
				<p className="m-2 small-font font-200">
					{portfolioTranslation.changedLanguage}
				</p>
			</div>
			{/* footer */}
			<footer className="p-4 md:px-16 ">
				<hr className="my-6 md:my-8" />
				<div className="flex items-center justify-between">
					<span className="small-font font-200">
						© {new Date().getFullYear()} Marco Olivieri
					</span>
					<div>
						<div className="inline-flex rounded-md" role="group">
							<button
								type="button"
								className="small-font font-200 inline-flex items-center py-2 px-4 text-sm font-medium bg-transparent rounded-l-lg"
								onClick={() => {
									setNotificationStyle("notification");
									setLang("it");
									setPortFolioTranslation(GetPortFolioTranslation("it"));
									setTimeout(() => {
										setNotificationStyle("notification-hide");
									}, 1000);
								}}
							>
								{"🇮🇹 "}
								{portfolioTranslation.itTranslation}
							</button>
							<button
								type="button"
								className="small-font font-200 inline-flex items-center py-2 px-4 text-sm font-medium bg-transparent rounded-r-md"
								onClick={() => {
									setNotificationStyle("notification");
									setLang("en");
									setPortFolioTranslation(GetPortFolioTranslation("en"));
									setTimeout(() => {
										setNotificationStyle("notification-hide");
									}, 1000);
								}}
							>
								{"🇬🇧 "}
								{portfolioTranslation.enTranslation}
							</button>
						</div>
					</div>
				</div>
			</footer>
		</div>
	);
}

export default App;

export default function GetPortFolioTranslation(
	lang?: string
): PortfolioTranslation {
	switch (lang?.toUpperCase()) {
		case "IT":
			return {
				changedLanguage: "Lingua impostata in italiano",
				enTranslation: "inglese",
				experiences: [
					{
						type: "personal",
						time: "Giugno, '93",
						title: "Nascita",
						location: "Noto, Sicilia, IT",
						locationURL: "https://goo.gl/maps/DPCCFkog8cFTmhaD9",
					},
					{
						type: "personal",
						time: "'03 al '06",
						location: "Parmain, Île-de-France, FR",
						locationURL: "https://goo.gl/maps/SYfFht1zJ7vhBB4Q8",
					},
					{
						type: "study",
						time: "'07 al '12",
						location: "Finale Emilia, Emilia Romagna, IT",
						locationURL: "https://goo.gl/maps/TecvzmLoFM5Mr7K99",
						title: "Liceo Scientifico Morando Morandi",
						titleURL: "https://www.liceomorandi.edu.it/",
					},
					{
						type: "study",
						time: "'13 al '15",
						location: "Bologna, Emilia Romagna, IT",
						locationURL: "https://goo.gl/maps/TdCgaMMnkuv64qfe7",
						title: "Alma Mater Studiorum - Università di Bologna",
						titleURL: "https://www.unibo.it/it",
						description: "Ingegneria Informatica - Non terminata",
					},
					{
						type: "work",
						time: "Luglio '15",
						location: "Mirandola, Emilia Romagna, IT",
						locationURL: "https://goo.gl/maps/pzQ1CT41fnvPKeWt8",
						title: "X Data Net",
						titleURL: "https://xdatanet.com/",
						description: "Software Developer",
					},
					{
						type: "study",
						time: "Gennaio '21",
						location: "Online",
						locationURL: "https://goo.gl/maps/pzQ1CT41fnvPKeWt8",
						title: "Corso Udemy",
						titleURL:
							"https://www.udemy.com/share/1013gG3@TaeC727_JLMFHvheljwUFmzVO_K29i9_hWvUfZ5sc7_7AC9jxmaKE1VdgMJk6Luj/",
						description: "The Complete 2021 Web Development Bootcamp",
					},
					{
						type: "work",
						time: "Maggio '21",
						location: "Casalecchio di Reno, Emilia Romagna, IT",
						locationURL: "https://goo.gl/maps/wZ21A6pakL8AFGPp7",
						title: "Alterna",
						titleURL: "https://www.alternanet.it/",
						description: "Software Developer",
					},
					{
						type: "work",
						time: "Aprile '22",
						location: "Rolle, Vaud, CH",
						locationURL: "https://maps.app.goo.gl/pD6jDA37WE1vuPpp9",
						title: "Schenk SA",
						titleURL: "https://www.schenk-wine.ch/fr",
						description: "Software Developer",
					},
				],
				experiments: [
					{
						title: "#1 - Simulazione di uno stormo",
						experimentSections: [
							{
								description:
									'Questo esperimento simula il comportamento di uno stormo di uccelli. L\'algoritmo che gestisce i singoli "uccelli" assegna ad ognuno una velocità ed una direzione casuale, e successivamente inizia a calcolare il movimento di ognuno ogni secondo basandosi su tre concetti fondamentali: "separazione", "allineamento" and "coesione"',
							},
							{
								description:
									"Ogni componente dello stormo sterza per evitare i componenti vicini",
								imageUrl:
									"https://upload.wikimedia.org/wikipedia/commons/e/e1/Rule_separation.gif",
								title: "Separazione",
							},
							{
								description:
									"Ogni componente dello stormo sterza per allineare la sua direzione a quella dei componenti vicini",
								imageUrl:
									"https://upload.wikimedia.org/wikipedia/commons/e/e1/Rule_alignment.gif",
								title: "Allineamento",
							},
							{
								description:
									"Ogni componente dello stormo sterza per muoversi verso la posizione media (centro di massa) dello stormo locale",
								imageUrl:
									"https://upload.wikimedia.org/wikipedia/commons/2/2b/Rule_cohesion.gif",
								title: "Coesione",
							},
						],
					},
					{
						title: "#2 - Triangolo di Sierpiński",
						experimentSections: [
							{
								description:
									"Il triangolo di Sierpiński è un frattale sottoforma di triangolo equilatero, suddiviso ricorsivamente in triangoli equilateri più piccoli",
							},
							{
								description:
									"Per generare questo tipo di frattale ho iniziato prendendo due vertici casuali del triangolo e ricavandone da essi il punto medio. A partire da questo passaggio ho iniziato a disegnare ricorsivamente i punti medi tra l'ultimo punto medio ricavato ed un vertice casuale del triandolo in un loop infinito.",
								imageUrl:
									"https://upload.wikimedia.org/wikipedia/commons/b/b8/Tri%C3%A2ngulo_de_Sierpinski.gif",
								title: "L'algoritmo",
							},
						],
					},
					{
						title: "#3 - Flusso del rumore di Perlin",
						experimentSections: [
							{
								description:
									"Per questo esperimento viene generato un flusso a partire dall'algoritmo di rumore di Perlin. Nel flusso appena generato rilascio delle particelle che saranno influenzate da quest'ultimo e genereranno un pattern unico ad ogni generazione",
							},
						],
					},
				],
				experimentsTitle: "Esperimenti di sviluppo",
				itTranslation: "italiano",
				presentationDescription: [
					`Sono uno sviluppatore con oltre ${
						new Date().getFullYear() - 2016
					} anni di esperienza nello sviluppo di applicazioni per prodotti Microsoft e nell'ERP Business Central.`,
					`Sono sempre allegro, curioso e pronto ad aiutare gli altri.`,
					`Il momento migliore della mia giornata inizia con le mie cuffia, la mia tastiera e del codice da scrivere.`,
				],
				presentationSentence: "Ciao,#Sono Marco",
				presentationTitle: [
					``,
					`Me`,
					`i miei `,
					`studi`,
					`e la mia `,
					`carriera`,
				],
				socials: [
					{
						social: "github",
						socialURL: "https://github.com/ArkoMO93",
					},
					{
						social: "instagram",
						socialURL: "https://www.instagram.com/marc0_0livieri/",
					},
					{
						social: "linkedin",
						socialURL: "https://www.linkedin.com/in/olivierimarco93/",
					},
					{
						social: "unsplash",
						socialURL: "https://unsplash.com/@marco_olivieri",
					},
				],
			};
		default:
			return {
				changedLanguage: "Language set to english",
				enTranslation: "english",
				experiences: [
					{
						type: "personal",
						time: "June, '93",
						title: "Born",
						location: "Noto, Sicily, IT",
						locationURL: "https://goo.gl/maps/DPCCFkog8cFTmhaD9",
					},
					{
						type: "personal",
						time: "'03 to '06",
						location: "Parmain, Île-de-France, FR",
						locationURL: "https://goo.gl/maps/SYfFht1zJ7vhBB4Q8",
					},
					{
						type: "study",
						time: "'07 to '12",
						location: "Finale Emilia, Emilia Romagna, IT",
						locationURL: "https://goo.gl/maps/TecvzmLoFM5Mr7K99",
						title: "High School Morando Morandi",
						titleURL: "https://www.liceomorandi.edu.it/",
					},
					{
						type: "study",
						time: "'13 to '15",
						location: "Bologna, Emilia Romagna, IT",
						locationURL: "https://goo.gl/maps/TdCgaMMnkuv64qfe7",
						title: "Alma Mater Studiorum - Bologna University",
						titleURL: "https://www.unibo.it/it",
						description: "Computer Engineer - Abbandoned",
					},
					{
						type: "work",
						time: "July '15",
						location: "Mirandola, Emilia Romagna, IT",
						locationURL: "https://goo.gl/maps/pzQ1CT41fnvPKeWt8",
						title: "X Data Net",
						titleURL: "https://xdatanet.com/",
						description: "Software Developer",
					},
					{
						type: "study",
						time: "January '21",
						location: "Online",
						locationURL: "https://goo.gl/maps/pzQ1CT41fnvPKeWt8",
						title: "Udemy Course",
						titleURL:
							"https://www.udemy.com/share/1013gG3@TaeC727_JLMFHvheljwUFmzVO_K29i9_hWvUfZ5sc7_7AC9jxmaKE1VdgMJk6Luj/",
						description: "The Complete 2021 Web Development Bootcamp",
					},
					{
						type: "work",
						time: "May '21",
						location: "Casalecchio di Reno, Emilia Romagna, IT",
						locationURL: "https://goo.gl/maps/wZ21A6pakL8AFGPp7",
						title: "Alterna",
						titleURL: "https://www.alternanet.it/",
						description: "Software Developer",
					},
					{
						type: "work",
						time: "April '22",
						location: "Rolle, Vaud, CH",
						locationURL: "https://maps.app.goo.gl/pD6jDA37WE1vuPpp9",
						title: "Schenk SA",
						titleURL: "https://www.schenk-wine.ch/fr",
						description: "Software Developer",
					},
				],
				experiments: [
					{
						title: "#1 - Boids simulation",
						experimentSections: [
							{
								description:
									'This experiment simulate the flocking behaviour of birds. The algorithm who manage the mouvement of each boid, assign a random direction and velocity in the beginning and then re-calculate the mouvement for each second based on three core concepts: "separation", "aligment" and "cohesion"',
							},
							{
								description:
									"Each boid steer to avoid crowding local flockmates",
								imageUrl:
									"https://upload.wikimedia.org/wikipedia/commons/e/e1/Rule_separation.gif",
								title: "Separation",
							},
							{
								description:
									"Each boid steer towards the average heading of local flockmates",
								imageUrl:
									"https://upload.wikimedia.org/wikipedia/commons/e/e1/Rule_alignment.gif",
								title: "Alignment",
							},
							{
								description:
									"Each boid steer steer to move towards the average position (center of mass) of local flockmates",
								imageUrl:
									"https://upload.wikimedia.org/wikipedia/commons/2/2b/Rule_cohesion.gif",
								title: "Cohesion",
							},
						],
					},
					{
						title: "#2 - Sierpiński triangle simulation",
						experimentSections: [
							{
								description:
									"The Sierpiński triangle is a fractal attractive fixed set with the overall shape of an equilateral triangle, subdivided recursively into smaller equilateral triangles",
							},
							{
								description:
									"To generate this kind of fractal i started by picking two random vertex of the triangle and took the midpoint between this two. From this step i started to draw the midpoint between this last point and a random vertex.",
								imageUrl:
									"https://upload.wikimedia.org/wikipedia/commons/b/b8/Tri%C3%A2ngulo_de_Sierpinski.gif",
								title: "The algorithm (Chaos Game)",
							},
						],
					},
					{
						title: "#3 - Perlin Flowfield",
						experimentSections: [
							{
								description:
									"For this experiment i've generate a flowfield based on the Perlin noise algorithm. In the flowfield i released some particles that will be affecter by the flow and will generate a unique pattern on each generation",
							},
						],
					},
				],
				experimentsTitle: "Development experiments",
				itTranslation: "italian",
				presentationDescription: [
					`I'm a developer with over than ${
						new Date().getFullYear() - 2016
					} years of experience in the software developer in Microsoft'product and ERP.`,
					`I'm always happy, curious and ready to help other people.`,
					`The best moment of my day is with my headphones, my keyboard and the code.`,
				],
				presentationSentence: "Hi,#I'm Marco",
				presentationTitle: [
					`About`,
					`me`,
					`my `,
					`studies`,
					`and my `,
					`career`,
				],
				socials: [
					{
						social: "github",
						socialURL: "https://github.com/ArkoMO93",
					},
					{
						social: "instagram",
						socialURL: "https://www.instagram.com/marc0_0livieri/",
					},
					{
						social: "linkedin",
						socialURL: "https://www.linkedin.com/in/olivierimarco93/",
					},
					{
						social: "unsplash",
						socialURL: "https://unsplash.com/@marco_olivieri",
					},
				],
			};
	}
}

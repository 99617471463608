import TimelineElement from "./TimelineElement";

type TimelineProps = {
	experiences?: Experience[];
};

export default function Timeline(_props: TimelineProps) {
	return (
		<ol className="md:flex">
			{_props.experiences
				? _props.experiences.map((experience, i) => (
						<TimelineElement key={i} experience={experience} />
				  ))
				: ""}
		</ol>
	);
}

import Sketch from "react-p5";
import * as p5 from "p5";

type BoidsSimulationCanvasProp = {
	active?: boolean;
};

const flock: Boid[] = [];
let mode: "none" | "create" | "delete" = "none"; // TODO: Implement mode
let mouseCircleAura: number = 1;
const mouseSensibility = 15; // used to determine at wich distance a boid is considered touched
const mousePerception = 100; // used to determine at wich distance escape to the mouse
let mousePress: boolean = false;

export default function BoidsSimulationCanvas(
	_prop: BoidsSimulationCanvasProp
) {
	function setup(_p5: p5, canvasParentRef: Element) {
		_p5.createCanvas(100, 100).parent(canvasParentRef);
		windowResized(_p5);

		const maxBoids = 100;
		for (let i = 0; i < maxBoids; i++) {
			flock.push(new Boid(_p5));
		}

		flock.push(new Boid(_p5, true));
	}

	function draw(_p5: p5) {
		if (!_prop.active) {
			return;
		}

		_p5.clear();
		flock.forEach((boid) => {
			boid.reposition(_p5);
			boid.reactToFlock(flock.slice(), _p5);
			boid.update();
			boid.show(_p5);
		});
		drawMouse(_p5);
	}

	function drawMouse(_p5: p5) {
		_p5.noFill();

		// external circle ("perception")
		_p5.stroke(0, 0, 0, 100 - mouseCircleAura);
		_p5.circle(_p5.mouseX, _p5.mouseY, mouseCircleAura);

		// internal circle ("touch")
		_p5.stroke(
			240,
			84,
			84,
			100 - ((mouseCircleAura % mouseSensibility) * 100) / mouseSensibility
		);
		_p5.circle(
			_p5.mouseX,
			_p5.mouseY,
			(mouseCircleAura * 2) % mouseSensibility
		);

		mouseCircleAura++;
		if (mouseCircleAura > mousePerception) {
			mouseCircleAura = 1;
		}
	}

	function mousePressed(_p5: p5) {
		switch (mode) {
			case "create":
				flock.push(new Boid(_p5, true, true));
				break;
			case "delete":
				mousePress = true;
				setTimeout(() => {
					mousePress = false;
				}, 700);
		}
	}

	function keyPressed(_p5: p5) {
		if (_p5.key.toUpperCase() === "M") {
			if (mode === "create") {
				mode = "delete";
			} else {
				mode = "create";
			}
		}
	}

	function windowResized(_p5: p5) {
		_p5.resizeCanvas(
			document.documentElement.clientWidth,
			document.documentElement.clientHeight
		);
	}

	return (
		<Sketch
			className={"h-full w-full flex items-center justify-center"}
			setup={setup}
			draw={draw}
			mousePressed={mousePressed}
			keyPressed={keyPressed}
			windowResized={windowResized}
		></Sketch>
	);
}

class Boid {
	acceleration: p5.Vector;
	alignmentLimit: number;
	alignmentSpeed: number;
	boidProportion: number;
	cohesionLimit: number;
	cohesionSpeed: number;
	me: boolean;
	separationLimit: number;
	separationSpeed: number;
	touchedByMouse: boolean = false;
	perception: number;
	position: p5.Vector;
	velocity: p5.Vector;

	constructor(
		_p5: p5,
		_me: boolean = false,
		_useMousePosition: boolean = false
	) {
		if (!_useMousePosition) {
			this.position = _p5.createVector(
				_p5.random(_p5.width),
				_p5.random(_p5.height)
			);
		} else {
			this.position = _p5.createVector(_p5.mouseX, _p5.mouseY);
		}
		this.velocity = p5.Vector.random2D();
		this.velocity = _p5.createVector(this.velocity.x, this.velocity.y);
		this.velocity.setMag(_p5.random(0.5, 1.5));
		this.acceleration = _p5.createVector();

		this.alignmentLimit = _p5.random(0.1, 0.2);
		this.alignmentSpeed = _p5.random(4, 5);
		this.boidProportion = _me ? 6 : _p5.random(3, 7);
		this.cohesionLimit = _p5.random(0.1, 0.2);
		this.cohesionSpeed = _p5.random(4, 5);
		this.me = _me;
		this.separationLimit = _p5.random(0.1, 0.2);
		this.separationSpeed = _p5.random(7, 8);
		this.perception = _p5.random(100, 150);
	}

	reactToFlock(_flock: Boid[], _p5: p5) {
		this.acceleration.set(0, 0);
		let avgVelocity = _p5.createVector(); // alignment
		let avgPosition = _p5.createVector(); // cohesion
		let avgSeparation = _p5.createVector(); // separation
		let mouseSeparation = _p5.createVector(); // separation - mouse
		let mousePosition = _p5.createVector(_p5.mouseX, _p5.mouseY);
		let tot = 0;

		_flock.forEach((boid) => {
			const dist = _p5.dist(
				this.position.x,
				this.position.y,
				boid.position.x,
				boid.position.y
			);
			if (boid !== this && dist <= this.perception) {
				avgVelocity.add(boid.velocity);
				avgPosition.add(boid.position);
				const diffBetweenPos = p5.Vector.sub(this.position, boid.position);
				diffBetweenPos.div(dist);
				avgSeparation.add(diffBetweenPos);
				tot++;
			}
		});

		const mouseDist = _p5.dist(
			this.position.x,
			this.position.y,
			mousePosition.x,
			mousePosition.y
		);

		if (mouseDist <= mousePerception) {
			const diffBetweenPos = p5.Vector.sub(this.position, mousePosition);
			diffBetweenPos.div(mouseDist);
			mouseSeparation.add(diffBetweenPos);
		}

		if (mouseDist <= mouseSensibility) {
			this.touchedByMouse = true;
			if (mousePress === true) {
				_flock.splice(_flock.indexOf(this), 1);
				return;
			}
		}

		if (tot > 0) {
			avgVelocity.div(tot);
			avgVelocity.setMag(this.alignmentSpeed);
			avgVelocity.sub(this.velocity);
			avgVelocity.limit(this.alignmentLimit);

			avgPosition.div(tot);
			avgPosition.sub(this.position);
			avgPosition.setMag(this.cohesionSpeed);
			avgPosition.sub(this.velocity);
			avgPosition.limit(this.cohesionLimit);

			avgSeparation.div(tot);
			avgSeparation.setMag(this.separationSpeed);
			avgSeparation.sub(this.velocity);
			avgSeparation.limit(this.separationLimit);

			mouseSeparation.setMag(this.separationSpeed);
			mouseSeparation.limit(this.separationLimit);
		}
		this.acceleration.add(avgVelocity);
		this.acceleration.add(avgPosition);
		this.acceleration.add(avgSeparation);
		this.acceleration.add(mouseSeparation);
	}

	reposition(_p5: p5) {
		if (this.position.x < 0) {
			this.position.x = _p5.width;
		} else if (this.position.x > _p5.width) {
			this.position.x = 0;
		}
		if (this.position.y < 0) {
			this.position.y = _p5.height;
		} else if (this.position.y > _p5.height) {
			this.position.y = 0;
		}
	}

	show(_p5: p5) {
		const backPosition = p5.Vector.add(
			this.position,
			p5.Vector.mult(this.velocity, -1)
				.normalize()
				.mult(this.boidProportion * 1.5)
		);
		const vertex = p5.Vector.add(
			this.position,
			p5.Vector.mult(this.velocity, 1)
				.normalize()
				.mult(this.boidProportion * 3)
		);
		const right = p5.Vector.add(
			backPosition,
			p5.Vector.mult(this.velocity, 1)
				.rotate(_p5.HALF_PI)
				.normalize()
				.mult(this.boidProportion)
		);
		const left = p5.Vector.add(
			backPosition,
			p5.Vector.mult(this.velocity, 1)
				.rotate(_p5.HALF_PI * 3)
				.normalize()
				.mult(this.boidProportion)
		);
		if (this.me) {
			_p5.stroke(240, 84, 84);
			_p5.fill(240, 84, 84);
		} else {
			if (this.touchedByMouse) {
				_p5.stroke(48, 71, 94);
				_p5.fill(48, 71, 94);
			} else {
				_p5.stroke(0, 0, 0, 100);
				_p5.noFill();
			}
		}
		_p5.beginShape();
		_p5.vertex(vertex.x, vertex.y);
		_p5.vertex(right.x, right.y);
		_p5.vertex(this.position.x, this.position.y);
		_p5.vertex(left.x, left.y);
		_p5.vertex(vertex.x, vertex.y);
		_p5.endShape();
	}

	update() {
		this.position.add(this.velocity);
		this.velocity.add(this.acceleration);
	}
}

import Sketch from "react-p5";
import * as p5 from "p5";

type Point = {
	x: number;
	y: number;
};

type SierpinskyTriangleCanvasProp = {
	active?: boolean;
};

let drawTriangle: boolean = true;
let lastPoint: Point;
let triangleVertex: Point[] = [];

export default function SierpinskyTriangleCanvas(
	_prop: SierpinskyTriangleCanvasProp
) {
	function setup(_p5: p5, canvasParentRef: Element) {
		_p5.createCanvas(100, 100).parent(canvasParentRef);
		windowResized(_p5);

		const cx = 0;
		const cy = -200;
		triangleVertex = [
			{
				x: cx * Math.cos(240) + cy * Math.sin(240),
				y: cx * Math.sin(240) - cy * Math.cos(240),
			},
			{
				x: cx * Math.cos(240) - cy * Math.sin(240),
				y: cx * Math.sin(240) - cy * Math.cos(240),
			},
			{ x: cx, y: cy },
		];
	}

	function draw(_p5: p5) {
		if (!_prop.active) {
			return;
		}

		const xoff = _p5.width / 2;
		const yoff = _p5.height / 2;

		_p5.stroke("black");
		_p5.strokeWeight(2);

		if (drawTriangle) {
			_p5.noFill();
			_p5.beginShape();
			_p5.vertex(triangleVertex[0].x + xoff, triangleVertex[0].y + yoff);
			_p5.vertex(triangleVertex[1].x + xoff, triangleVertex[1].y + yoff);
			_p5.vertex(triangleVertex[2].x + xoff, triangleVertex[2].y + yoff);
			_p5.vertex(triangleVertex[0].x + xoff, triangleVertex[0].y + yoff);
			_p5.endShape();
			drawTriangle = false;
		}

		calculateNextPoint();
		_p5.point(lastPoint.x + xoff, lastPoint.y + yoff);
	}

	function calculateNextPoint() {
		if (!lastPoint) {
			lastPoint = triangleVertex[Math.floor(Math.random() * 3)];
		}
		let randomVertexPoint: Point =
			triangleVertex[Math.floor(Math.random() * 3)];
		while (randomVertexPoint === lastPoint) {
			randomVertexPoint = triangleVertex[Math.floor(Math.random() * 3)];
		}
		lastPoint = {
			x: (lastPoint.x + randomVertexPoint.x) / 2,
			y: (lastPoint.y + randomVertexPoint.y) / 2,
		};
	}

	function windowResized(_p5: p5) {
		_p5.resizeCanvas(
			document.documentElement.clientWidth,
			document.documentElement.clientHeight
		);
		drawTriangle = true;
	}

	return (
		<Sketch
			className={"h-full w-full flex items-center justify-center"}
			setup={setup}
			draw={draw}
		></Sketch>
	);
}

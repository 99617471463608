import { AnimationEvent, MouseEvent } from "react";
import "./AnimatedText.css";

const rubberbandAnimationClassName = "moving-letter-animation";
const rubberbandAnimationStartClassName = "moving-letter-init";
const rubberbandClassName = "moving-letter";

type animatedTextProps = {
	className?: string;
	tag?: string;
	text?: string;
};

function Span_OnAnimationEnd(_span: HTMLSpanElement) {
	if (_span.classList.contains(rubberbandAnimationClassName)) {
		_span.classList.remove(rubberbandAnimationClassName);
	}
	if (_span.classList.contains(rubberbandAnimationStartClassName)) {
		_span.classList.remove(rubberbandAnimationStartClassName);
		_span.style.animationDelay = "";
	}
}

function Span_OnMouseEnter(_span: HTMLSpanElement) {
	_span.classList.add(rubberbandAnimationClassName);
}

function GetAnimatedTextInSpan(_text: string) {
	return _text.split("").map((e, i) => {
		return e === " " ? (
			" "
		) : e === "#" ? (
			<br key={i} className={rubberbandClassName}></br>
		) : (
			<span
				key={i}
				className={`${rubberbandClassName} ${rubberbandAnimationStartClassName}`}
				style={{ animationDelay: `${i / 10}s` }}
				onMouseEnter={(event: MouseEvent<HTMLSpanElement>) => {
					Span_OnMouseEnter(event.target as HTMLSpanElement);
				}}
				onAnimationEnd={(event: AnimationEvent<HTMLSpanElement>) => {
					Span_OnAnimationEnd(event.target as HTMLSpanElement);
				}}
			>
				{e}
			</span>
		);
	});
}

export default function AnimatedText(prop: animatedTextProps) {
	const CustomTag = `${
		prop.tag ? prop.tag : "div"
	}` as keyof JSX.IntrinsicElements;
	return (
		<CustomTag
			className={`${
				prop.className ? prop.className : ""
			} ${rubberbandClassName}`}
		>
			{prop.text ? GetAnimatedTextInSpan(prop.text) : ""}
		</CustomTag>
	);
}

import { useEffect, useState } from "react";
import "./PopupCard.css";

type PopupCardProps = {
	onCloseHandler: () => void;
	sections: { description: string; imageUrl?: string; title?: string }[];
	title: string;
	visible: boolean;
};

export default function PopupCard(_props: PopupCardProps) {
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		setVisible(_props.visible);
	}, [_props.visible]);

	return (
		<div
			className="fixed top-0 bottom-0 left-0 right-0 z-50 bg-overlay"
			onClick={() => {
				setVisible(false);
				_props.onCloseHandler();
			}}
			style={{
				display: visible ? "block" : "none",
				opacity: visible ? "1" : "0",
			}}
		>
			<div
				className="flex flex-col mt-10 md:mt-20 m-auto p-4 rounded-md w-[80%] md:w-[70%] bg-popup max-h-[90%] md:max-h-[80%]"
				onClick={(e) => {
					e.stopPropagation();
				}}
			>
				<div className="flex justify-between border-b-2 mb-6 pb-1 px-4">
					<h2 className="mt-1 color-fourth small-font font-200">
						{_props.title}
					</h2>
					<button
						className="font-200 small-font rounded color-fourth border px-2 opacity-50 hover:opacity-100 cursor-default"
						onClick={() => {
							setVisible(false);
							_props.onCloseHandler();
						}}
					>
						esc
					</button>
				</div>
				<div className="px-4 overflow-y-scroll">
					{_props.sections.map((value, i) => {
						return (
							<div key={i}>
								{value.title ? (
									<div className="small-font border-b-2 mb-6 pb-1 px-4 sticky top-0 bg-color-first">
										{value.title}
									</div>
								) : (
									""
								)}
								<div
									className={
										"mb-6 px-4 flex flex-col md:flex-row justify-between gap-3 " +
										(i % 2 === 0 && value.imageUrl ? "md:flex-row-reverse" : "")
									}
								>
									<div
										className={
											"small-font font-200 " +
											(value.imageUrl ? "w-52 md:w-96" : "w-full")
										}
									>
										{value.description}
									</div>
									{value.imageUrl ? (
										<div
											className={
												"grow md:grow-0 flex " +
												(i % 2 !== 0 ? "md:justify-end" : "")
											}
										>
											<img
												className="object-scale-down"
												src={value.imageUrl}
												alt=""
											/>
										</div>
									) : (
										""
									)}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
}

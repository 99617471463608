import Sketch from "react-p5";
import * as p5 from "p5";

type PerlinNoiseParticleProp = {
	active?: boolean;
};

let cols: number,
	rows: number,
	zoffset: number = 0;
let flowfield: p5.Vector[] = [];
const particles: Particle[] = [];
const particlesCount = 1000;
const scale = 20;
let x_increment: number = 0;
let y_increment: number = 0;
let z_increment: number = 0;

export default function PerlinNoiseParticleCanvas(
	_prop: PerlinNoiseParticleProp
) {
	function setup(_p5: p5, canvasParentRef: Element) {
		_p5.createCanvas(100, 100).parent(canvasParentRef);
		windowResized(_p5);
		cols = Math.floor(_p5.width / scale);
		rows = Math.floor(_p5.height / scale);
		for (let i = 0; i < particlesCount; i++) {
			particles.push(new Particle(_p5));
		}
	}

	function draw(_p5: p5) {
		if (!_prop.active) {
			return;
		}
		randomizeIncrement(_p5);
		let yoffset = 0;
		for (let y = 0; y < rows; y++) {
			let xoffset = 0;
			for (let x = 0; x < cols; x++) {
				const angle = _p5.noise(xoffset, yoffset, zoffset) * _p5.TWO_PI;
				let v = p5.Vector.fromAngle(angle);
				v = _p5.createVector(v.x, v.y);
				v.setMag(1);
				flowfield[x * y * cols] = v;
				xoffset += x_increment;
			}
			yoffset += y_increment;
		}
		zoffset += z_increment;

		particles.forEach((particle) => {
			particle.applyFlowField(flowfield);
			particle.reposition(_p5);
			particle.update(_p5);
			particle.show(_p5);
		});
	}

	function windowResized(_p5: p5) {
		_p5.resizeCanvas(
			document.documentElement.clientWidth,
			document.documentElement.clientHeight
		);
	}

	return (
		<Sketch
			className={"h-full w-full flex items-center justify-center"}
			setup={setup}
			draw={draw}
		></Sketch>
	);
}

function randomizeIncrement(_p5: p5) {
	while (x_increment === 0) {
		x_increment = 0.1;
		x_increment = _p5.random(-0.5, 0.5);
	}
	while (y_increment === 0) {
		y_increment = 0.1;
		y_increment = _p5.random(-0.5, 0.5);
	}
	while (z_increment === 0) {
		z_increment = 0.01;
		z_increment = _p5.random(-0.5, 0.5);
	}
}

class Particle {
	acceleration: p5.Vector;
	lastPosition: p5.Vector;
	maxVelocity: number = 5;
	position: p5.Vector;
	velocity: p5.Vector;

	constructor(_p5: p5) {
		this.position = _p5.createVector(
			_p5.random(_p5.width),
			_p5.random(_p5.height)
		);
		this.lastPosition = _p5.createVector(this.position.x, this.position.y);
		this.velocity = _p5.createVector();
		this.acceleration = _p5.createVector();
	}

	applyForce(_force: p5.Vector) {
		this.acceleration.set(0, 0);
		this.acceleration = p5.Vector.add(this.acceleration, _force);
	}

	applyFlowField(flowfield: p5.Vector[]) {
		const x = Math.floor(this.position.x / scale);
		const y = Math.floor(this.position.y / scale);
		const force = flowfield[x * y * cols];
		this.applyForce(force);
	}

	reposition(_p5: p5) {
		if (this.position.x < 0) {
			this.position.x = _p5.width;
		} else if (this.position.x > _p5.width) {
			this.position.x = 0;
		}
		if (this.position.y < 0) {
			this.position.y = _p5.height;
		} else if (this.position.y > _p5.height) {
			this.position.y = 0;
		}
		this.lastPosition = _p5.createVector(this.position.x, this.position.y);
	}

	show(_p5: p5) {
		_p5.stroke(0, 10);
		_p5.strokeWeight(1);
		_p5.line(
			this.lastPosition.x,
			this.lastPosition.y,
			this.position.x,
			this.position.y
		);
	}

	update(_p5: p5) {
		this.velocity.add(this.acceleration);
		this.velocity.limit(this.maxVelocity);
		this.lastPosition = _p5.createVector(this.position.x, this.position.y);
		this.position.add(this.velocity);
	}
}
